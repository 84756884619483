<template>
  <div style="font-size:14px; border-radius:5px" class="p-1">
    <body-card>
    <b-overlay :show="loading">
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
            <b-row class="p-1">
              <hr>
                <h5>
                    <center><b>{{ $t('tradeTariffApplication.feedback_and_rating') }}</b></center>
                </h5>
              <hr>
                <b-col xs="12" sm="12" md="3">
                </b-col>
                <!-- <b-col xs="12" sm="12" md="6">
                  <ValidationProvider name="Rating" vid="rating_rate" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-for="rating_rate"
                      slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                        {{ $t('externalTradeTraiff.rating_rate')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-rating
                         style="padding: 40px; font-size: 50px"
                          v-model="form.rating_rate"
                          id="rating_rate"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        </b-form-rating>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col> -->
                <b-col xs="12" sm="12" md="6">
                  <ValidationProvider name="Comment" vid="rating_id" rules="" v-slot="{ errors }">
                    <b-form-group
                      label-for="rating_id"
                    >
                    <template v-slot:label>
                      {{ $t('tradeTariffConfig.comment')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="form.rating_id"
                      :options="ratingList"
                      id="rating_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="3">
                </b-col>
                <b-col xs="12" sm="12" md="3">
                </b-col>
                <b-col xs="12" sm="12" md="6">
                  <ValidationProvider name="Comment" vid="comment" rules="">
                    <b-form-group
                      label-for="comment"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('externalTradeTraiff.feedback')}}
                      </template>
                      <b-form-textarea
                        rows="4"
                        v-model="form.comment"
                        placeholder="Write your opinion!"
                        id="comment"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </b-form-textarea>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="3">
                </b-col>
            </b-row>
            <b-row class="text-right mb-3" style="padding-right:20px;">
              <b-col>
                  <b-button type="submit" variant="success" @click="formStatus(2)" class="mr-2 btn-sm">{{$t('globalTrans.submit') }}</b-button>
                  <b-button variant="danger" class="btn-sm" @click="clearData()" to="/trade-and-tariff-panel/rating-and-feedback">{{ $t('globalTrans.cancel') }}</b-button>
              </b-col>
            </b-row>
          </b-form>
      </ValidationObserver>
      </b-overlay>
    </body-card>
  </div>
</template>
<script>
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { externalFeedbackStore } from '../../api/routes'
import { mapGetters } from 'vuex'
import AddressHelper from '../../../../../../utils/area-type-address'

export default {
  name: 'Form',
  components: {
  },
  data () {
    return {
      AddressHelper: AddressHelper,
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      valid: null,
      errors: [],
      form: {
          rating_rate: 1,
          rating_id: 0,
          comment: ''
      }
    }
  },
  created () {
    if (this.authUser && this.commonProfile) {
        this.profile = this.commonProfile
    } else {
      this.$router.push('/external-user/profile-update')
    }
  },
  computed: {
     ratingList: function () {
      const objectData = this.$store.state.ExternalUserService.tradeTariff.commonObj.ratingList.filter(item => item.status === 1)
      return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
        })
    },
    currentLocale () {
      return this.$i18n.locale
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    applicationTypeList: function () {
      return this.$store.state.ExternalUserService.tradeTariff.commonObj.applicationTypeList.filter(item => item.status === 1)
    },
    ...mapGetters({
      authUser: 'Auth/authUser',
      commonProfile: 'Auth/commonProfile'
    })
  },
  watch: {
  },
  methods: {
    clearData () {
      this.form.rating_rate = ''
      this.form.comment = ''
    },
    async saveUpdate () {
      const isValid = await this.$refs.form.validate()
      if (isValid) {
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          let result = null
          const loadingState = { loading: false, listReload: false }
          this.form.add_more_files = this.add_more_files
          result = await RestApi.postData(tradeTariffServiceBaseUrl, externalFeedbackStore, this.form)
          loadingState.listReload = true
          this.$store.dispatch('mutateCommonProperties', loadingState)

          if (result.success) {
           this.$store.dispatch('EBizManagementSystemService/mutateCommonObj', { hasDropdownLoaded: false })
            this.form.rating_id = 0
            this.form.comment = ''
            this.$toast.success({
              title: this.$t('globalTrans.success'),
              message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
              color: '#D6E09B'
            })
            this.$router.push('/trade-and-tariff-panel/rating-and-feedback')
          } else {
            this.$refs.form.setErrors(result.errors)
          }
       }
    }
  }
}
</script>
<style>
.ql-image{
    visibility: hidden !important;
}
.ql-video{
    visibility: hidden !important;
}
</style>
